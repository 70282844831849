@import "~bootswatch/dist/lumen/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/lumen/bootswatch";

@import "./components/card-actions/card-actions.scss"; @import "./components/info-icon/info-icon.scss"; @import "./components/overlay/overlay.scss"; @import "./components/side-nav/side-nav.scss"; @import "./components/site-layout/site-layout.scss";
@import "./views/home/home.scss"; @import "./views/login/login.scss";

#root {
    position: relative;
    min-height: 100vh;
}

/** Utiltity **/
.bg-hover-white {
  &:hover {
      background-color: $white !important;
      color: var(--bs-dark) !important;
  }
}